<template>
  <div class="help-content-box">
    <div class="help-content">
      <div class="video-introduction mb20">
        <div class="title">{{ $t('key1005648') }}</div>
        <div class="video-item bottom-border">
          <div class="sub-title">{{ $t('key1005562') }} </div>
          <div class="video-item-desc" @click="gotoPage(1)">
                  <span class="desc">{{ $t('key1005562') }}</span>
                  <img class="play" src="../../../../assets/images/help/icon-play.png" />
                  <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
        <div class="video-item video-item-tit">
          <div class="sub-title">{{ $t('key1005649') }}  </div>
        </div>
        <div class="video-item mb25">
          <div class="sub-title sub-title-t">{{ $t('key1005650') }}  </div>
          <div class="video-item-desc" @click="gotoPage(6)">
            <span class="desc">{{ $t('key1005563') }}</span>
            <img class="play" src="../../../../assets/images/help/icon-play.png" />
            <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
        <div class="video-item">
          <div class="sub-title sub-title-t">{{ $t('key1005651') }}  </div>
          <div class="video-item-desc" @click="gotoPage(2)">
            <span class="desc">{{ $t('key1005564') }}</span>
            <img class="play" src="../../../../assets/images/help/icon-play.png" />
            <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
      </div>
      <div class="video-introduction">
        <div class="title">
          <p>{{ $t('key1005652') }}</p>
          <div class="title-desc">{{ $t('key1005653') }} + {{ $t('key1005654') }} + {{ $t('key1005655') }}</div>
        </div>
        <div class="video-item mb25">
          <div class="sub-title sub-title-t">{{ $t('key1005656') }}</div>
          <div class="video-item-desc" @click="gotoPage(3)">
            <span class="desc">{{ $t('key1005657') }}</span>
            <img class="play" src="../../../../assets/images/help/icon-play.png" />
            <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
        <div class="video-item mb25">
          <div class="sub-title sub-title-t">{{ $t('key1005658') }} </div>
          <div class="video-item-desc" @click="gotoPage(5)">
            <span class="desc">{{ $t('key1005659') }}</span>
            <img class="play" src="../../../../assets/images/help/icon-play.png" />
            <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
        <div class="video-item">
          <div class="sub-title sub-title-t">{{ $t('key1005660') }} </div>
          <div class="video-item-desc" @click="gotoPage(4)">
            <span class="desc">{{ $t('key1004615') }}</span>
            <img class="play" src="../../../../assets/images/help/icon-play.png" />
            <img class="play-h" src="../../../../assets/images/help/icon-play-h.png" />
          </div>
        </div>
      </div>
      <div class="videos">
    </div>

    </div>
  </div>
</template>

<script>
import Mixin from '@/components/mixin/common_mixin';
import {videos} from '@/utils/enum';



export default {
  name: 'helpVideoMore',
  mixins: [Mixin],
  data () {
    return {
      videos: videos
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {
    gotoPage(id) {
      let origin = window.location.origin;
      if (origin.includes('localhost')) {
        window.open(origin+'/vedio.html?id='+id, '_blank');
      } else {
        window.open(origin+'/yms-shopping-service/vedio.html?id='+id, '_blank');
      }
    },
  },
  components: {
  }
};
</script>

<style  scoped lang="less">
.mb25 {
  margin-bottom: 25px;
}
.help-content-box {
  background-color: #f2f2f2;
  .help-content {
    width: 1200px;
    margin: 0 auto 25px auto;
  }
}
.video-introduction {
  padding: 32px 23px;
  background: #ffffff;

  .bottom-border {
    padding-bottom: 25px;
    border-bottom: 1px solid #EDEDED;
  }


  .title {

    font-size: 18px;
    font-weight: 600;
    color: #FF5310;
    margin-bottom: 20px;
  }
  .title-desc {
    font-weight: initial;
    color: #919DB1;
    font-size: 13px;
  }
  .video-item-tit {
    margin: 27px 0 20px 0;
  }
  .video-item {
    display: flex;
    align-items: center;
    .sub-title {
      min-width: 224px;
      font-size: 18px;
      font-weight: 600;
      color: #000000;
      line-height: 32px;
      padding-right: 15px;
    }
    .sub-title-t {
      font-weight: initial;
      font-size: 16px;
    }
    .video-item-desc {
      min-width: 260px;
      height: 60px;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: box-shadow 0.2s ease;
      .desc {
        font-size: 16px;
        font-weight: 400;
        padding-right: 15px;
        color: #000000;
      }
      .play {
        display: block;
        width: 30px;
      }
      .play-h {
        display: none;
      }
      &:hover {
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.15);
        .play {
          display: none;
        }
        .play-h {
          display: block;
          width: 30px;
        }
      }
    }
  }
}
</style>
